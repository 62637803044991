<template>
  <div>
    <LangDialogPublicity ref="langDialog" />
    <v-row>
      <v-col cols="4">
        <v-text-field rounded filled dense v-model="keyword" append-icon="mdi-magnify" single-line hide-details
          clearable :placeholder="$t('field.search')" />
      </v-col>
      <v-col cols="8" :class="'text-' + $float">
        <SettingsDialog ref="settingsDialog" @newLimit="newLimit" @deleteLimit="deleteLimit" />

        <FilterDialog @filter="$emit('filter', $event)" />

        <Export link="publicities/export" />

        <Refresh @refresh="$emit('refresh')" />

        <v-btn color="primary" rounded depressed @click="newPublicity">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('btn.add') }}
        </v-btn>

        <LimitForm ref="formLimit" @refresh="refreshSettings" />

        <ConfirmDialog ref="confirmDialog" />

      </v-col>
    </v-row>
  </div>
</template>

<script>
import FilterDialog from "@/views/publicities/list/components/FilterDialog";
import SettingsDialog from "@/views/publicities/list/components/SettingsDialog";
import LimitForm from "@/views/publicities/settings/LimitForm";

export default {
  components: { FilterDialog, SettingsDialog, LimitForm },
  data() {
    return {
      keyword: null
    }
  },
  methods: {
    newPublicity() {
      this.$refs.langDialog.open()
    },
    newLimit() {
      this.$refs.formLimit.open()
    },
    refreshSettings() {
      this.$refs.settingsDialog.getSettings();
    },
    async deleteLimit(item) {
      if (await this.$refs.confirmDialog.open('', this.$i18n.t('message.confirmDeleteLimit'))) {


        this.$refs.settingsDialog.removeLimit(item);
      }
    }
  },
  watch: {
    keyword() {
      this.$emit('search', this.keyword)
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-dialog max-width="500"
            scrollable
            v-model="dialog"
            persistent>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
          >
            <v-icon>mdi-tune</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.filter') }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>
        <v-icon left>mdi-tune</v-icon>
        Filtrage
        <v-spacer/>
        <v-icon @click="dialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-select v-model="filter.type"
                      prepend-icon="mdi-filter-outline"
                      multiple chips deletable-chips small-chips dense clearable
                      :items="['Small','Large']"
                      label="Type..." hide-details></v-select>
          </v-col>

          <v-col cols="12">
            <v-select v-model="filter.status"
                      prepend-icon="mdi-filter-outline"
                      multiple chips deletable-chips small-chips dense clearable
                      :items="['ok','no']"
                      label="Status..." hide-details></v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="filter.date"
                          prepend-icon="mdi-filter-outline"
                          append-icon="mdi-calendar"
                          type="date"
                          dense clearable
                          label="Planification.." hide-details></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="filter.action"
                          prepend-icon="mdi-filter-outline"
                          append-icon="mdi-link"
                          type="text"
                          dense clearable
                          label="Action.." hide-details></v-text-field>
          </v-col>


        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary"
               :disabled="isDisabled"
               depressed block @click="handleFilter">
          Recherche
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      filter: {}
    }
  },
  methods: {
    handleFilter() {
      this.dialog = false
      this.$emit('filter', this.filter)
    },
  },
  computed: {
    isDisabled() {
      return Object.values(this.filter).every(x => x === null || x === '' || x.length === 0)
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-dialog v-if="form.lang"
            :max-width="form.lang.length === 1 ? '500' : form.lang.length === 2  ? '600' : '700'"
            scrollable
            v-model="dialog">
    <form @submit.prevent="save">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title>
          Modifier les langues
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-4">


        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 :disabled="isLoading"
                 type="submit"
                 depressed>
            <v-icon left>mdi-content-save</v-icon>
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>

</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      isLoading: false,
      form: {},
      errors: {},
    }
  },
  methods: {
    open(item) {
      this.form = item
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    save() {
      this.errors = {}
      this.$Progress.start()
      this.isLoading = true

      let url = "compaigns/update-info"

      HTTP.post(url, this.form).then(() => {
        this.$emit('refresh')
        this.isLoading = false
        this.$successMessage = this.$i18n.t('message.succssStore')
        this.$Progress.finish()
      }).catch((err) => {
        this.isLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = this.$i18n.t('message.catchError')
        console.log(err)
      })
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <v-dialog :max-width="isLoading ? 500 : 700" scrollable v-model="dialog">

    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>

        Détails publicité
        <v-spacer />
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-4">

        <v-row v-if="isLoading">
          <v-col cols="12">
            <div class="text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="!isLoading">
          <v-col cols="12" v-if="publicity">
            <v-card outlined>
              <a :href="publicity.action" :alt="publicity.action" target="_blank">
                <v-img :src="$fileUrl + publicity.image" aspect-ratio="1.7" contain v-if="publicity.image"></v-img>
              </a>

              <v-simple-table>
                <template v-slot:default>
                  <tbody>

                    <tr class="bg-snipper">
                      <td class="font-weight-bold">Statut</td>
                      <td>

                        <SwitchStatus url="publicities/change-status" @overlay="overlay = $event" :id="publicity.id"
                          :value="publicity.is_active" @refresh="getPublicity(publicity.id)" />
                      </td>
                    </tr>

                    <tr>
                      <td class="font-weight-bold">Image</td>
                      <td>
                        <v-img v-if="publicity.image" :src="$fileUrl + publicity.image" width="40" height="40"
                          class="zoom-pointer rounded"
                          @click="[image = [{ src: $fileUrl + publicity.image }], visible = true]" />

                        <v-img v-else :src="require('@/assets/no-image.png')" width="40" height="40" />
                      </td>
                    </tr>

                    <tr class="bg-snipper">
                      <td class="font-weight-bold">Description</td>
                      <td>{{ publicity.description.fr || '-' }}</td>
                    </tr>

                    <tr>
                      <td class="font-weight-bold">Date de début</td>
                      <td>{{ publicity.start_date }}</td>
                    </tr>

                    <tr class="bg-snipper">
                      <td class="font-weight-bold">Date de fin</td>
                      <td>{{ publicity.end_date }}</td>
                    </tr>

                    <tr>
                      <td class="font-weight-bold">Nombre de POS</td>
                      <td>
                        <v-chip color="primary" small>
                          <v-icon small left>mdi-mobile</v-icon>
                          {{ publicity.salepoints.length }}
                        </v-chip>
                      </td>
                    </tr>

                  </tbody>
                </template>
              </v-simple-table>

            </v-card>
          </v-col>

          <v-col>
            <v-card flat>
              <v-card-text>

                <!-- <v-skeleton-loader v-if="isLoading" type="table"/> -->

                <div>
                  <v-simple-table v-if="salepoints.length">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>{{ $t('field.image') }}</th>
                          <th>{{ $t('field.code') }}</th>
                          <th>{{ $t('field.name') }}</th>
                          <th>{{ $t('field.phone') }}</th>
                          <th>{{ $t('field.city') }}</th>
                          <th>{{ $t('field.region') }}</th>
                          <th>Compte OPUS</th>
                          <th>Compte Gifty</th>
                          <th>{{ $t('field.points') }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in salepoints" :key="i">
                          <td>
                            <v-avatar v-if="item.image" size="35" class="zoom-pointer"
                              @click="[image = [{ src: $fileUrl + item.image }], visible = true]">
                              <v-img :src="$fileUrl + item.image"></v-img>
                            </v-avatar>
                            <v-avatar v-else size="35" class="zoom-pointer"
                              @click="[image = [{ src: require('@/assets/avatar.png') }], visible = true]">
                              <v-img :src="require('@/assets/avatar.png')"></v-img>
                            </v-avatar>
                          </td>
                          <td>{{ item.code }}</td>
                          <td>
                            <span class="text-no-wrap">{{ item.name }}</span>
                          </td>
                          <td>{{ item.phone }}</td>
                          <td>{{ item.city ? item.city.name : '-' }}</td>
                          <td>{{ item.region ? item.region.name : '-' }}</td>
                          <td>
                            <v-icon color="success" dense v-if="item.opus_account">mdi-check</v-icon>
                            <v-icon color="red" dense v-else>mdi-close</v-icon>
                          </td>
                          <td>
                            <v-icon color="success" dense v-if="item.gifty_account_id">mdi-check</v-icon>
                            <v-icon color="red" dense v-else>mdi-close</v-icon>
                          </td>
                          <td>
                            <v-chip small color="success">{{ item.points | toCurrency }}</v-chip>
                          </td>
                          <td>
                            <v-icon color="blue" @click="showMore(item)">mdi-plus-circle</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <NoResult v-else size="180" />
                </div>

              </v-card-text>


            </v-card>

            <v-divider v-if="total > $totalPerPage" />
            <v-card-actions v-if="total > $totalPerPage">
              <div class="w-100">
                <v-select dense filled hide-details :items="$perPage" v-model="perPage"></v-select>
              </div>
              <v-spacer />
              <v-pagination total-visible="10" circle v-model="pagination.current" :length="pagination.total"
                @input="getSalepointsByIds">

              </v-pagination>
            </v-card-actions>
          </v-col>

        </v-row>



      </v-card-text>

    </v-card>

  </v-dialog>

</template>

<script>
import { HTTP } from "@/http-common";

export default {
  components: {},
  data() {
    return {
      dialog: false,
      isLoading: false,
      publicity: null,
      lang: null,

      ids: [],
      filter: {
        databaseIds: []
      },
      salepoints: [],
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },
    }
  },
  methods: {
    open(publicity, lang) {
      //this.publicity = publicity
      this.points = []
      this.lang = lang
      this.dialog = true
      this.getPublicity(publicity.id)
    },
    close() {
      this.dialog = false
    },
    getPublicity(id) {
      this.$Progress.start()
      this.isLoading = true
      HTTP.get('/publicities/show/' + id).then(res => {
        //console.log(res)

        this.publicity = res.data.data
        res.data.data.salepoints.forEach((item) => {
          this.ids.push(item.id);
        })

        this.$Progress.finish()
        this.isLoading = false
        this.getSalepointsByIds()

      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.isLoading = false
        console.log(err)
      })
    },
    getSalepointsByIds() {
      this.$Progress.start()
      this.isLoading = true

      HTTP.get('/databases/salepoints-by-ids?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
          ids: this.salepointIds ? this.salepointIds : this.ids,
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;
        this.salepoints = res.data.data.data
        this.$Progress.finish()
        this.isLoading = false
      }).catch(err => {
        this.$Progress.fail()
        this.isLoading = false
        console.log(err)
      })
    },
  },


  created() {

  },
  watch: {
  },

}
</script>

<style scoped>

</style>
<template>

  <v-dialog max-width="700" scrollable v-model="dialog" persistent>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.settings') }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>
        <v-icon left>mdi-cog</v-icon>
        {{ $t('btn.settings') }}
        <v-spacer />

        <v-icon @click="dialog = false">mdi-close</v-icon>


      </v-card-title>
      <v-divider />
      <v-card-text class="pa-4">
        <v-row>



          <v-col cols="12" v-if="isLoading">
            <div class="text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
          </v-col>
          <v-col cols="12" v-if="!isLoading">
            <div class="d-flex justify-space-between">
              <div>
                <h3 class="py-2 blue--text">
                  <v-avatar color="blue" size="40">
                    <v-icon dense dark>mdi-file</v-icon>
                  </v-avatar>

                  Limits :

                </h3>
              </div>
              <v-btn color="primary" rounded depressed @click="newLimit">
                <v-icon left>mdi-plus</v-icon>
                {{ $t('btn.add') }}
              </v-btn>

            </div>


            <v-simple-table>
              <template v-slot:default>
                <tbody>

                  <tr class="bg-snipper" v-for="(limit, index) in settings.limites" :key="index">
                    <td class="font-weight-bold">
                      Max
                    </td>

                    <td>
                      {{ limit.limit }}

                    </td>
                    <td>
                      <span v-if="limit.start_date">{{ limit.start_date }}</span>
                      <span v-if="limit.end_date"> - {{ limit.end_date }}</span>
                    </td>

                    <td>
                      <Btn color="red" icon="mdi-close" :title="$t('btn.delete')" @click="deleteLimit(index)" />
                    </td>
                  </tr>

                </tbody>
              </template>
            </v-simple-table>
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" depressed block @click="handleSave">
          Valider
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>



</template>

<script>
import { HTTP } from "@/http-common";

export default {

  data() {
    return {
      dialog: false,
      isLoading: false,
      settings: null
    }
  },
  methods: {
    handleSave() {
      this.dialog = false
      this.$emit('filter', this.filter)
    },
    getSettings() {
      this.errors = {}
      this.$Progress.start()
      this.isLoading = true

      let url = "publicities/settings"

      HTTP.get(url).then((response) => {
        this.$emit('refresh')
        this.isLoading = false
        this.$Progress.finish()
        this.settings = response.data
      }).catch((err) => {
        this.isLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = this.$i18n.t('message.catchError')
        console.log(err)
      })
    },
    newLimit() {
      this.$emit('newLimit')
    },
    deleteLimit(item) {
      this.$emit('deleteLimit', item)
    },
    removeLimit(item) {
      console.log("removing" + item)

      this.settings.limites.splice(item, 1);

      this.errors = {}
      this.$Progress.start()
      this.isLoading = true

      let url = "publicities/settings/limit/" + item

      HTTP.delete(url).then(() => {
        this.$emit('refresh')
        this.isLoading = false
        this.$Progress.finish()
        this.getSettings()
      }).catch((err) => {
        this.isLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = this.$i18n.t('message.catchError')
        console.log(err)
      })
    }
  },
  mounted() {
    this.getSettings()
  },

  watch: {
    dialog() {
      if (this.dialog) {
        this.getSettings()
      }
    }
  },
}
</script>

<template>
  <div>

    <ConfirmDialog ref="confirmDialog"/>
    <UpdateInfoDialog ref="updateInfoDialog" @refresh="$emit('refresh')"/>
    <UpdatePlanningDialog ref="updatePlanningDialog" @refresh="$emit('refresh')"/>
    <UpdateLangDialog ref="updateLangDialog" @refresh="$emit('refresh')"/>

    <v-menu
        transition="slide-x-transition"
        bottom
        right
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="blue"
            icon
            dark
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>

        <v-list-item @click="handleDelete()">
          <v-list-item-icon>
            <v-icon color="blue">mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Supprimer</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$refs.updateInfoDialog.open(publicity)">
          <v-list-item-icon>
            <v-icon color="blue">mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Modifier les information</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$refs.updatePlanningDialog.open(publicity)">
          <v-list-item-icon>
            <v-icon color="blue">mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Modifier la planification</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$refs.updateLangDialog.open(publicity)">
          <v-list-item-icon>
            <v-icon color="blue">mdi-earth</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Modifier les langues</v-list-item-title>
        </v-list-item>

      </v-list>
    </v-menu>
  </div>
</template>

<script>
import UpdateInfoDialog from "@/views/publicities/list/components/UpdateInfoDialog";
import UpdatePlanningDialog from "@/views/publicities/list/components/UpdatePlanningDialog";
import UpdateLangDialog from "@/views/publicities/list/components/UpdateLangDialog";
import {HTTP} from "@/http-common";

export default {
  components: {UpdatePlanningDialog, UpdateInfoDialog, UpdateLangDialog},
  props: ['publicity'],
  methods: {
    async handleDelete() {
      if (await this.$refs.confirmDialog.open()) {
        this.$Progress.start()
        this.$emit('overlay', true)
        HTTP.delete('/publicities/delete/'+this.publicity.id).then(() => {
          this.$Progress.finish()
          this.$emit('overlay', false)
          this.$successMessage = this.$i18n.t('message.succssDelete')
          this.$emit('refresh')
        }).catch(err => {
          this.$Progress.fail()
          this.$errorMessage = this.$i18n.t('message.catchError')
          this.$emit('overlay', false)
          console.log(err)
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" scrollable>
      <form @submit.prevent="save">
        <v-card :loading="isLoading" :disabled="isLoading">
          <v-card-title>
            Nouvelle limitation
            <v-spacer />
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-4">

            <v-text-field :label="$t('field.limit')" :error-messages="errors.name" class="required"
              v-model="form.limit">
            </v-text-field>

            <DateRange :dates.sync="form.dates"
              text-info='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut porta dolor'
              @dates="form.dates = $event" />


          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" :loading="isLoading" type="submit" depressed>
              <v-icon left>mdi-content-save</v-icon>
              {{ $t('btn.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";

export default {
  data: () => ({
    dialogTitle: null,
    isLoading: false,
    dialog: false,
    form: {
      limit: 10,
      dates: []
    },
    errors: {},
  }),
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    save() {
      this.errors = {}
      this.$Progress.start()
      this.isLoading = true

      let url = "settings/publicity/limit"

      let data = {
        limit: this.form.limit,
      }

      if (this.form.dates.length == 2) {
        data.start_date = this.form.dates[0];
        data.end_date = this.form.dates[1];
      }

      HTTP.post(url, data).then(() => {
        this.$emit('refresh')
        this.isLoading = false
        this.close()
        this.$successMessage = this.$i18n.t('message.succssStore')
        this.form = {
          limit: 10,
          dates: []
        }
        this.$Progress.finish()
      }).catch((err) => {
        this.isLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = this.$i18n.t('message.catchError')
        console.log(err)
      })
    }
  }
}
</script>
<template>
  <v-dialog v-if="form.lang"
            :max-width="form.lang.length === 1 ? '500' : form.lang.length === 2  ? '600' : '700'"
            scrollable
            v-model="dialog">
    <form @submit.prevent="save">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title>
          Modifier les informations
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-4">

          <v-row class="mt-1">
                
            <v-col>
                <PublicityType @set-publicity-type-id="form.type = $event"
                             :text-info="$t('info.publicityType')"
                             :publicity-type-id.sync="form.type"/>
            </v-col>
          </v-row>

          <v-row class="mt-1">
            <v-col>
              <v-text-field :label="$t('field.action')"
                            hide-details="auto"
                            :error-messages="errors['action']"
                            :hint="$t('field.action')"
                            persistent-hint
                            class="required"
                            v-model="form.action">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-show="form.lang.includes('fr')">
              <v-text-field :label="$t('field.name')"
                            hide-details="auto"
                            :error-messages="errors['name.fr']"
                            :hint="$t('hint.fr')"
                            persistent-hint
                            class="required"
                            v-model="form.name.fr">
              </v-text-field>
            </v-col>
            <v-col v-show="form.lang.includes('en')">
              <v-text-field :label="$t('field.name')"
                            hide-details="auto"
                            :error-messages="errors['name.en']"
                            :hint="$t('hint.en')"
                            persistent-hint
                            class="required"
                            v-model="form.name.en"/>
            </v-col>
            <v-col v-show="form.lang.includes('ar')">
              <v-text-field :label="$t('field.name')"
                            hide-details="auto"
                            :error-messages="errors['name.ar']"
                            :hint="$t('hint.ar')"
                            persistent-hint
                            class="required"
                            v-model="form.name.ar"/>
            </v-col>
          </v-row>

          <UploadFile type="image"
                      @loading="isLoading=$event"
                      @file="form.image = $event"
                      :path.sync="form.image"/>

          <v-row>
            <v-col v-show="form.lang.includes('fr')">
              <v-textarea rows="1"
                          :label="$t('field.description')"
                          hide-details="auto"
                          :error-messages="errors['description.fr']"
                          :hint="$t('hint.fr')"
                          persistent-hint
                          v-model="form.description.fr"/>
            </v-col>
            <v-col v-show="form.lang.includes('en')">
              <v-textarea rows="1"
                          :label="$t('field.description')"
                          hide-details="auto"
                          :error-messages="errors['description.en']"
                          :hint="$t('hint.en')"
                          persistent-hint
                          v-model="form.description.en"/>
            </v-col>
            <v-col v-show="form.lang.includes('ar')">
              <v-textarea rows="1"
                          :label="$t('field.description')"
                          hide-details="auto"
                          :error-messages="errors['description.ar']"
                          :hint="$t('hint.ar')"
                          persistent-hint
                          v-model="form.description.ar"/>
            </v-col>
          </v-row>


        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 :disabled="isLoading"
                 type="submit"
                 depressed>
            <v-icon left>mdi-content-save</v-icon>
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>

</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      isLoading: false,
      form: {
        id: null,
        lang: null,
        name: null,
        description: null,
        image: null,
      },
      errors: {},
    }
  },
  methods: {
    open(item) {
      this.form.id = item.id
      this.form.type = item.type
      this.form.action = item.action
      this.form.lang = item.lang
      this.form.name = item.name
      this.form.description = item.description
      this.form.image = item.image
      this.dialog = true
      this.errors = {}
    },
    close() {
      this.dialog = false
    },
    save() {
      this.errors = {}
      this.$Progress.start()
      this.isLoading = true

      let url = "publicities/update-info"

      HTTP.post(url, this.form).then(() => {
        this.$emit('refresh')
        this.close()
        this.isLoading = false
        this.$successMessage = this.$i18n.t('message.succssStore')
        this.$Progress.finish()
      }).catch((err) => {
        this.isLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = this.$i18n.t('message.catchError')
        console.log(err)
      })
    }
  },
}
</script>

<style scoped>

</style>